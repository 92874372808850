import Footer from "../components/Footer";
import Header from "../components/Header";

import "../styles/Privacy.css";

import "../App.css";
import ScrollMe from "../components/ScrollMe";
import MobMenu from "../components/MobMenu";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <Header />

      <div
        id="main"
        className="privacy-policy"
        style={{
          width: "75%",
          margin: "0 auto 0 auto",
          maxWidth: "1000px",
          //   minHeight: "100vh",
          padding: "200px 0 100px 0",
        }}
      >
        <h1>Terms and Conditions </h1>
        <h2>Introduction – Welcome to Swarm!</h2>

        <ol>
          <li>
            <b>Acceptance of Terms</b> <br /> Welcome to Swarm! These Terms and
            Conditions ("Terms") govern your access and use of the Swarm mobile
            application ("App") and related services ("Services"). By accessing
            or using the App and Services, you agree to be bound by these Terms.
            If you do not agree with any part of these Terms, please do not use
            the App or Services.
          </li>

          <li>
            <b>Use of the App</b> <br />
            <ul>
              <li>
                Eligibility: You must be at least 18 years old to use the App
                and Services. By using the App, you represent and warrant that
                you are of legal age to form a binding contract with Swarm.
              </li>
              <li>
                Account Registration: To access certain features of the App, you
                may be required to create an account. You are responsible for
                maintaining the confidentiality of your account credentials and
                are solely responsible for all activities that occur under your
                account.
              </li>
              <li>
                User Conduct: You agree not to use the App and Services for any
                unlawful or unauthorized purpose and to comply with all
                applicable laws and regulations. You will not engage in any
                activity that could harm, interfere with, or disrupt the App or
                Services or any user's experience.
              </li>
            </ul>
          </li>

          <li>
            <b> Intellectual Property </b>
            <ul>
              <li>
                Ownership: Swarm retains all rights, title, and interest in and
                to the App, Services, and all related intellectual property. You
                may not use, copy, reproduce, or distribute any content from the
                App without our prior written consent.
              </li>
              <li>
                User Content: By using the App, you may submit or post content
                ("User Content"). You retain ownership of your User Content, but
                you grant Swarm a worldwide, royalty-free, non-exclusive,
                perpetual, and transferable license to use, reproduce, modify,
                and distribute your User Content for the purpose of providing
                the Services.
              </li>
            </ul>
          </li>

          <li>
            <b>Third-Party Links and Content</b>
            <ul>
              <li>
                The App may contain links to third-party websites or services
                that are not owned or controlled by Swarm. We do not endorse or
                assume any responsibility for the content, privacy policies, or
                practices of third-party sites or services. Your interactions
                with such third parties are solely between you and them.
              </li>
            </ul>
          </li>
          <li>
            <b> Limitation of Liability</b>
            <ul>
              <li>
                Disclaimer: The App and Services are provided on an "as-is" and
                "as-available" basis without any warranties, express or implied.
                Swarm does not warrant that the App will be error-free, secure,
                or uninterrupted.
              </li>
              <li>
                Limitation of Liability: In no event shall Swarm, its officers,
                directors, employees, or agents be liable to you or any third
                party for any indirect, incidental, special, or consequential
                damages arising out of or in connection with the App or
                Services.
              </li>
            </ul>
          </li>
          <li>
            <b>Indemnification</b>
            <ul>
              <li>
                You agree to indemnify and hold Swarm harmless from and against
                any claims, damages, losses, liabilities, and expenses arising
                out of or related to your use of the App or Services or any
                violation of these Terms.
              </li>
            </ul>
          </li>
          <li>
            <b>Modification and Termination </b>
            <ul>
              <li>
                Swarm reserves the right to modify, suspend, or terminate the
                App and Services or these Terms at any time without prior
                notice. If you disagree with any changes, your sole remedy is to
                discontinue using the App and Services.
              </li>
            </ul>
          </li>
          <li>
            <b>Governing Law and Dispute Resolution </b>{" "}
            <ul>
              <li>
                These Terms shall be governed by and construed in accordance
                with the laws of Delaware and New York. Any dispute arising out
                of or in connection with these Terms shall be resolved through
                arbitration.
              </li>
            </ul>
          </li>
          <li>
            <b>Contact Us </b>{" "}
            <ul>
              <li>
                If you have any questions, concerns, or inquiries regarding
                these Terms, please contact us at{" "}
                <Link to="mailto:support@get-swarm.com">
                  support@get-swarm.com
                </Link>
                .
              </li>
            </ul>
          </li>
        </ol>
        <h2>Thank you for using Swarm!</h2>
      </div>

      <Footer />
      <ScrollMe />
    </>
  );
};

export default Terms;
