const InstaSvg = (props:any)=>{

        return(
            <>
            <svg {...props} width="23.237" height="22.532" viewBox="0 0 23.237 22.532">
            <path id="Path_79" data-name="Path 79" d="M16.824,0H6.412A6.325,6.325,0,0,0,0,6.218v10.1a6.325,6.325,0,0,0,6.412,6.218H16.824a6.325,6.325,0,0,0,6.413-6.218V6.218A6.325,6.325,0,0,0,16.824,0Zm4.351,16.314a4.291,4.291,0,0,1-4.351,4.219H6.412a4.291,4.291,0,0,1-4.351-4.219V6.218A4.291,4.291,0,0,1,6.412,2H16.824a4.291,4.291,0,0,1,4.351,4.219v10.1Z" transform="translate(0 0)"/>
            <path id="Path_80" data-name="Path 80" d="M267.7,261.724a5.808,5.808,0,1,0,5.987,5.806A5.905,5.905,0,0,0,267.7,261.724Zm0,9.612a3.808,3.808,0,1,1,3.926-3.807A3.872,3.872,0,0,1,267.7,271.336Z" transform="translate(-256.081 -256.264)"/>
            <path id="Path_81" data-name="Path 81" d="M761.2,180.473a1.542,1.542,0,0,0-1.068.429,1.432,1.432,0,0,0,0,2.074,1.545,1.545,0,0,0,2.137,0,1.436,1.436,0,0,0,0-2.074A1.542,1.542,0,0,0,761.2,180.473Z" transform="translate(-743.345 -176.708)"/>
            </svg>

            </>
        )
}

export default InstaSvg