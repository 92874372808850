import "../styles/Home.css";
import Header from "../components/Header";
import Input from "antd/es/input/Input";
import { Button, Modal } from "antd";
import slider_logo from "../svg/slider_logo.svg";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import ScrollMe from "../components/ScrollMe";
import Switch from "../components/switch/Switch";
import TextArea from "antd/es/input/TextArea";
import TwitterSvg from "../svg/Twitter";
import LinkedinSvg from "../svg/LinkedIn";
import InstaSvg from "../svg/Insta";
import TiktokSvg from "../svg/Tiktok";
import MobMenu from "../components/MobMenu";
import { toast } from "react-hot-toast";
import ContactForm from "../components/ContactForm";
import axios from "axios";
const Terms = require("../files/Terms.pdf");
const Privacy = require("../files/Privacy.pdf");

const fade_images = [
  { image: "./images/1.jpg", name: "REAL ESTATE", tag: "for" },
  { image: "./images/2.jpg", name: "NEW BABY", tag: "for your" },
  { image: "./images/3.jpg", name: "ENGAGEMENT", tag: "for your" },
  { image: "./images/4.jpg", name: "MODELING", tag: "for" },
  { image: "./images/5.jpg", name: "WEDDING", tag: "for your" },
  { image: "./images/6.jpg", name: "IN THE PARK", tag: "for when you're" },
  { image: "./images/7.jpg", name: "BIRTHDAY", tag: "for your" },
];

const Fade = require("react-reveal/Fade");

const Home = () => {
  const [showPlaceHolder, setShowPlaceHolder] = useState(false);
  const [myInterval, setMyInterval] = useState<any>();

  let imageCounter = 0;
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    // setTimeout(() => {
    //   setIsModalOpen(false);
    // }, 3000);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const handleSubmit = async (e: any) => {
  //   e.preventDefault();

  //   if (!email || !name || !type) {
  //     toast.error("Please fill in all fields.");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("EMAIL", email);
  //   formData.append("FNAME", name);
  //   formData.append("RADIO", type);

  //   try {
  //     const response = await fetch(
  //       "https://get-swarm.us21.list-manage.com/subscribe/post?u=63fe510beff280c6fe14e3995&amp;id=da9a86e3d7&amp;f_id=0072e2e6f0",
  //       {
  //         method: "POST",
  //         body: formData,
  //         mode: "no-cors",
  //       }
  //     );

  //     console.log("response: ", response);
  //     if (response.status === 200) {
  //       console.log("Subscription successful");
  //       // toast.success("Success");
  //     }
  //   } catch (error) {
  //     toast.error("Error");
  //     console.error("Error:", error);
  //   }
  // };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!email || !name || !type) {
      toast.error("Please fill in all fields.");
      return;
    }

    const formData = {
      type,
      email,
      FNAME: name,
      LNAME: "",
    };

    console.log("Form Data with type:", formData);

    await axios
      .post(
        "https://dev-azproduction-api.flynautstaging.com/auth/subscribe-mailchimp",
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Form submitted successfully");
          toast.success("Success");
          setEmail("");
          setName("");

          setModalHeading("Thank you for signing up!");
          setModalText("A member of our team will be in touch.");
          setIsModalOpen(true);

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          // console.error("Form submission error:", response.statusText);
          // console.error("RESPONSE:", response.data.error);
          toast.error(response?.data?.error);

          setModalHeading("There was an error signing up.");
          setModalText("Please check your info and try again.");
          setIsModalOpen(true);
          setTimeout(() => {
            setIsModalOpen(false);
            setModalHeading("");
            setModalText("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Form submission error:", error);
        toast.error("Something went wrong");
      });
  };

  // const getscroll = ()=>{

  //     var b=document.body;
  //     var d=document.documentElement;
  //     d=(d.clientHeight)? d : b;
  //     return d.scrollTop;

  //   }

  useEffect(() => {
    window.addEventListener("resize", resizeFunc);
    resizeFunc();

    var divs = document.getElementsByClassName("image-effect");

    for (var i = 0; i < divs.length; i++) {
      if (divs[i]) {
        let x: any = divs[i];
        x.style.opacity = "0";
        x.style.transition = "all 1s ease";
      }
    }
    if (divs[0]) {
      let y: any = divs[0];
      y.style.opacity = "1";
    }

    setMyInterval(setInterval(imageEffect, 3000));

    return () => {
      window.removeEventListener("resize", resizeFunc);
      clearInterval(myInterval);
    };
  }, []);

  const resizeFunc = () => {
    if (window.innerWidth >= 1000) {
      setShowPlaceHolder(true);
    } else {
      setShowPlaceHolder(false);
    }
  };

  const imageEffect = () => {
    let next;
    if (imageCounter === 6) {
      next = 0;
    } else {
      next = imageCounter + 1;
    }

    var divs = document.getElementsByClassName("image-effect");

    let y: any = divs[imageCounter];
    let x: any = divs[next];
    if (y) {
      y.style.opacity = "0";
    }
    if (x) {
      x.style.opacity = "1";
    }

    if (imageCounter === 6) imageCounter = 0;
    else imageCounter++;
  };

  const scroolIntoView = () => {
    const element = document.getElementById("signup");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scroolIntoViewContactUs = () => {
    const element = document.getElementById("contactUs");
    console.log("element: ", element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header />

      <section className="homeTop">
        <div className="videoWrapper">
          <video autoPlay muted loop playsInline>
            <source src="/videos/background-1.mov" />
          </video>
        </div>

        <div className="topOverlay">
          <Fade bottom>
            <div className="hBot">
              <Link to="https://twitter.com/get_swarm">
                <TwitterSvg className="w-logo" />
              </Link>

              <Link to="https://www.linkedin.com/company/swarm-app/">
                <LinkedinSvg className="w-logo" />
              </Link>

              <Link to="https://www.instagram.com/get.swarm/">
                <InstaSvg className="w-logo" />
              </Link>

              {/* <img src={fb} alt="icon" /> */}
              <Link to="https://www.tiktok.com/@get.swarm">
                <TiktokSvg className="w-logo" />
              </Link>
            </div>
          </Fade>

          <div className="textWrapper">
            <Fade bottom>
              <p className="htTopPc">COMING SOON // MARCH 2024</p>
            </Fade>
            <Fade bottom>
              <p className="htCenter">
                On-demand photoshoots.
                <br /> Just tap.
              </p>
            </Fade>
            <Fade bottom>
              <p className="htBot">
                Talented, local photographers become your own, personal
                paparazzi—anywhere and everywhere you go. Enjoy any type of
                photo shoot you want and then share your pics or video with your
                friends, family, followers, and fans the next day.
              </p>
            </Fade>
            <Fade bottom>
              <button onClick={scroolIntoView} className="btn htBtn">
                Sign up for Swarm
              </button>
            </Fade>
            <Fade bottom>
              <div className="aside app-buttons">
                <Link to="#">
                  <img src="./images/app-store.png" alt="App Store" />
                </Link>
                <Link to="#">
                  <img src="./images/google-play.png" alt="Google Play" />
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}

      {/* Mobile Version Overlay */}
      <section className="mobOverLay">
        <div>
          <Fade bottom>
            <p className="mobTopPc">COMING SOON // MARCH 2024</p>
          </Fade>
          <Fade bottom>
            <p className="mobCenter">
              On-demand photoshoots.
              <br /> Just tap.
            </p>
          </Fade>
          <Fade bottom>
            <p className="mobBot">
              Talented, local photographers become your own, personal
              paparazzi—anywhere and everywhere you go. Enjoy any type of photo
              shoot you want and then share your pics or video with your
              friends, family, followers, and fans the next day.
            </p>
          </Fade>
          <a href="#signup">
            <Fade bottom>
              <button className="btn htBtn">Sign up for Swarm</button>
            </Fade>
          </a>
          <Fade bottom>
            <div className="aside app-buttons">
              <Link to="#">
                <img src="./images/app-store.png" alt="App Store" />
              </Link>
              <Link to="#">
                <img src="./images/google-play.png" alt="Google Play" />
              </Link>
            </div>
          </Fade>
        </div>
      </section>

      <section className="btfCnt btfFirst" id="signup">
        <div>
          <Fade bottom>
            <p className="btnMobMsg">SIGN UP</p>
          </Fade>

          <Fade bottom>
            <p className="b-heading">Be among the first to get the app!</p>
          </Fade>

          <Fade bottom>
            <p className="g-subHeading">
              The Swarm app will go live in March, 2024. Sign up to be notified
              when it goes live.
            </p>
          </Fade>

          <Fade bottom>
            <div className="btfBtnCnt">
              <div>
                <Switch
                  theme="dark"
                  text={["Customer", "Photographer"]}
                  setType={setType}
                />
              </div>

              <div>
                <div className="superInpCnt">
                  <label>Your name</label>
                  <div className="inpCnt">
                    <div className="inpCntInner">
                      <Input
                        bordered={false}
                        placeholder="Your name"
                        className="myselect"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="superInpCnt">
                  <label>Email address</label>
                  <div className="inpCnt">
                    <div className="inpCntInner">
                      <Input
                        bordered={false}
                        placeholder="Email address"
                        className="myselect"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <button
                      className="btn pcBtn btnInp"
                      style={{
                        padding: "9px 45px",
                        fontSize: "22px",
                      }}
                      onClick={handleSubmit}
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              </div>

              <button className="btn htBtn mobBtn" onClick={handleSubmit}>
                Sign up
              </button>
            </div>
          </Fade>
        </div>
      </section>

      <section className="aboutSection" id="aboutUs">
        <div>
          <div className="aboutHeaders">
            <Fade bottom>
              <p className="y-heading leftAlign">
                AFFORDABLE, ON-DEMAND PHOTOSHOOTS
              </p>
            </Fade>

            <Fade bottom>
              <p className="b-heading leftAlign">
                Customized photoshoots with local, pro photographers.
              </p>
            </Fade>
          </div>

          <div className="aboutImage">
            <Fade right>
              {/* <div className="twoImage">
                    <div>
                    <img src="./images/image1.png" alt="demo" />
                    <img src="./images/image2.png" alt="demo" />
                    </div>
                </div> */}
              <div className="twoImagePc">
                <img src="./images/image1.png" alt="demo" />
                <img src="./images/image2.png" alt="demo" />
              </div>
            </Fade>
          </div>

          <div className="aboutContents">
            <Fade bottom>
              <div>
                <p className="g-subHeading leftAlign">
                  There’s never been a convenient way to find or hire talented,
                  local photographers—until now. With the Swarm app, you’ll
                  never need to take another picture of yourself, beg a stranger
                  to take pictures of you, or crowdsource feedback from friends
                  before posting on Instagram and TikTok.
                </p>

                <p
                  className="g-subHeading leftAlign"
                  style={{ marginTop: "32px" }}
                >
                  Once you have the Swarm app on your phone, you’ll always have
                  instant and affordable access to the coolest, most
                  experienced, and most influential photographers in the city.
                </p>
              </div>
            </Fade>

            <Fade bottom>
              <div className="atcBtnCnt">
                <div>
                  <button className="btn htBtn">Sign up for Swarm</button>
                </div>

                <div>
                  <Link to="https://twitter.com/get_swarm">
                    <TwitterSvg />
                  </Link>

                  <Link to="https://www.linkedin.com/company/swarm-app/">
                    <LinkedinSvg />
                  </Link>

                  <Link to="https://www.instagram.com/get.swarm/">
                    <InstaSvg />
                  </Link>

                  {/* <img src={fb} alt="icon" /> */}
                  <Link to="https://www.tiktok.com/@get.swarm">
                    <TiktokSvg />
                  </Link>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div
                style={{ width: "auto", margin: "32px 0 0 0", display: "flex" }}
              >
                <button
                  className="btn htBtn mobBtn"
                  style={{ width: "100%", maxWidth: "500px", margin: "auto" }}
                >
                  Sign up for Swarm
                </button>
              </div>
            </Fade>
          </div>

          <div className="aboutPcContents">
            <Fade bottom>
              <p className="y-heading leftAlign">
                AFFORDABLE, ON-DEMAND PHOTOSHOOTS
              </p>
            </Fade>

            <Fade bottom>
              <p className="b-heading leftAlign">
                Customized photoshoots with local, pro photographers.
              </p>
            </Fade>

            <Fade bottom>
              <div>
                <p className="g-subHeading leftAlign">
                  There’s never been a convenient way to find or hire talented,
                  local photographers—until now. With the Swarm app, you’ll
                  never need to take another picture of yourself, beg a stranger
                  to take pictures of you, or crowdsource feedback from friends
                  before posting on Instagram and TikTok.
                </p>

                <p
                  className="g-subHeading leftAlign"
                  style={{ marginTop: "32px" }}
                >
                  Once you have the Swarm app on your phone, you’ll always have
                  instant and affordable access to the coolest, most
                  experienced, and most influential photographers in the city.
                </p>
              </div>
            </Fade>

            <Fade bottom>
              <div className="atcBtnCnt">
                <div onClick={scroolIntoView}>
                  <button className="btn htBtn">Sign up for Swarm</button>
                </div>

                <div>
                  <Link to="https://twitter.com/get_swarm">
                    <TwitterSvg className="y-logo" />
                  </Link>

                  <Link to="https://www.linkedin.com/company/swarm-app/">
                    <LinkedinSvg className="y-logo" />
                  </Link>

                  <Link to="https://www.instagram.com/get.swarm/">
                    <InstaSvg className="y-logo" />
                  </Link>

                  {/* <img src={fb} alt="icon" /> */}
                  <Link to="https://www.tiktok.com/@get.swarm">
                    <TiktokSvg className="y-logo" />
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <Fade>
        <section className="slider">
          {fade_images.map((elem, index) => (
            <div className="imgWrapper image-effect" key={index}>
              <div className="wrapperBk">
                <img src={elem.image} alt="slider" />
              </div>
              <div className="wrapperFront">
                <div className="sfCnt">
                  <div className="sfUp">
                    <img src={slider_logo} alt="logo" />
                    <p>{elem.tag}</p>
                  </div>
                  <div className="sfDown">
                    <p>{elem.name}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </Fade>

      <section className="bookShot">
        <div>
          <Fade bottom>
            <p className="y-heading">FAST, EASY & AFFORDABLE</p>
          </Fade>

          <Fade bottom>
            <p className="b-heading">Book a shoot in a minute or less.</p>
          </Fade>

          <Fade bottom>
            <p className="g-subHeading">
              Book a shoot a week—or an hour—in advance. Check out available
              photographers’ portfolios, hire the one you like best, enjoy your
              shoot, and you’ll get your pics or video back within 48 hours or
              less.
            </p>
          </Fade>

          <div className="stepsCnt">
            <Fade left>
              <div className="stepBox">
                <img src="./images/step1.png" alt="step" />
                <div className="stepTxtCnt">
                  <div className="yellowCircle">1</div>
                  <p className="g-subHeading">
                    Pick the date and time of your shoot.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div className="stepBox">
                <img src="./images/step2.png" alt="step" />
                <div className="stepTxtCnt">
                  <div className="yellowCircle">2</div>
                  <p className="g-subHeading">
                    Pick the location of your shoot.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade right>
              <div className="stepBox">
                <img src="./images/step3.png" alt="step" />
                <div className="stepTxtCnt">
                  <div className="yellowCircle">3</div>
                  <p className="g-subHeading">
                    Pick the photographer you’d like to work with.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section className="gridCnt">
        <div className="tile">
          <Fade left>
            <div className="tileImgCnt"></div>
          </Fade>

          <Fade right>
            <div className="tileContent">
              <div>
                <p className="y-heading leftAlign">SWARM</p>
                <p className="b-heading leftAlign">For Customers</p>
                <p className="g-subHeading leftAlign">
                  Once you’ve downloaded the Swarm app, you’ll have easy and
                  convenient access to professional photo and video shoots
                  whenever you want, wherever you go. Work with different talent
                  every time you book a shoot, get your pics and video back the
                  next day.
                </p>
              </div>
            </div>
          </Fade>
        </div>

        <div className="tile">
          <Fade right>
            <div className="tileImgCnt"></div>
          </Fade>

          <Fade left>
            <div className="tileContent">
              <div>
                <p className="y-heading leftAlign">SWARM</p>
                <p className="b-heading leftAlign">For Photographers</p>
                <p className="g-subHeading leftAlign">
                  Whether you’re an amateur, mid-level or professional
                  photographer, you should apply to become a Swarm photographer.
                  As a Swarm photographer, you get to make your own schedule,
                  have reliable and consistent work, and not have to deal with
                  the hassle of managing shoots.
                </p>

                <div className="tileBtnCnt">
                  {/* <Link to="/photographer">
                        <button className="tileMobBtn">
                            TELL ME MORE {<ArrowRight fontSize="large" sx={{margin:"0"}} />}
                        </button>
                        </Link> */}
                  <Link to="/photographer">
                    <button className="btn htBtn">Tell me more</button>
                  </Link>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <div className="tile">
          <Fade left>
            <div className="tileImgCnt"></div>
          </Fade>

          <Fade right>
            <div className="tileContent">
              <div>
                <p className="y-heading leftAlign">SWARM</p>
                <p className="b-heading leftAlign">For Brands</p>
                <p className="g-subHeading leftAlign">
                  In a world where you can never have enough fresh content to
                  share—now you can hire Swarm talent to help you build out the
                  ultimate content library. If you’re interested in learning
                  more about or corporate packages and pricing,
                  <Link
                    to="#"
                    className="clickTxt"
                    style={{
                      fontSize: "0.9em",
                      fontWeight: "bold",
                      marginLeft: "5px",
                    }}
                    onClick={scroolIntoViewContactUs}
                  >
                    click here
                  </Link>
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </section>

      <section className="btfCnt btfFirst" id="bottomSignup">
        <div>
          <Fade bottom>
            <p className="btnMobMsg">SIGN UP</p>
          </Fade>

          <Fade bottom>
            <p className="b-heading">Be among the first to get the app!</p>
          </Fade>

          <Fade bottom>
            <p className="g-subHeading">
              The Swarm app will go live in March, 2024. Sign up to be notified
              when it goes live.
            </p>
          </Fade>

          <Fade bottom>
            <div className="btfBtnCnt">
              <div>
                <Switch
                  theme="dark"
                  text={["Customer", "Photographer"]}
                  setType={setType}
                />
              </div>

              <div>
                <div className="superInpCnt">
                  <label>Your name</label>
                  <div className="inpCnt">
                    <div className="inpCntInner">
                      <Input
                        bordered={false}
                        placeholder="Your name"
                        className="myselect"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="superInpCnt">
                  <label>Email address</label>
                  <div className="inpCnt">
                    <div className="inpCntInner">
                      <Input
                        bordered={false}
                        placeholder="Email address"
                        className="myselect"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <button
                      className="btn pcBtn btnInp"
                      style={{
                        padding: "9px 45px",
                        fontSize: "22px",
                      }}
                      onClick={handleSubmit}
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              </div>

              <button className="btn htBtn mobBtn" onClick={handleSubmit}>
                Sign up
              </button>
            </div>
          </Fade>
        </div>
      </section>

      <section className="form" id="contactUs">
        <Fade left>
          <div className="formLeft">
            <div className="flContent">
              <p className="y-heading leftAlign">CONTACT US</p>
              <p className="b-heading leftAlign">We’d love to hear from you</p>
              <p className="g-subHeading leftAlign">
                We’re excited to launch the Swarm app in New York City this
                September. Whether you’re a customer wanting to download the
                app, a photographer wanting to sign up to join our community of
                talent, or an influencer wanting to collaborate with us, please
                fill out the form and, we’ll get back to you as soon as
                possible!
              </p>
            </div>
          </div>
        </Fade>

        <Fade right>
          <ContactForm />
        </Fade>
      </section>

      <section className="social">
        <div>
          <Fade bottom>
            <p className="y-heading">WE'RE QUITE SOCIAL</p>
          </Fade>

          <Fade bottom>
            <p className="b-heading">
              And, we’d be honored to have you follow us
            </p>
          </Fade>

          <Fade bottom>
            <div className="iconbox">
              <Link to="https://twitter.com/get_swarm">
                <TwitterSvg className="w-logo" />
              </Link>

              <Link to="https://www.linkedin.com/company/swarm-app/">
                <LinkedinSvg className="w-logo" />
              </Link>

              <Link to="https://www.instagram.com/get.swarm/">
                <InstaSvg className="w-logo" />
              </Link>

              {/* <img src={fb} alt="icon" /> */}
              <Link to="https://www.tiktok.com/@get.swarm">
                <TiktokSvg className="w-logo" />
              </Link>
            </div>
          </Fade>
        </div>
      </section>

      <Footer />
      <ScrollMe />

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={null}
        centered
        wrapClassName="message-modal"
        width={"650px"}
      >
        {modalHeading && <h2>{modalHeading}</h2>}
        {modalText && <p>{modalText}</p>}
      </Modal>
    </>
  );
};

export default Home;
