import React, {createContext, useEffect, useRef, useState } from "react";
import { MenuType } from "../@types/global";
import MobMenu from "../components/MobMenu";

export const globalData = createContext<MenuType | null >(null);

type contextProp={
    children:React.ReactNode
}

export const GlobalContext = (props:contextProp)=>{

  const [ mobMenuEnabled, setMobMenuEnabled ] = useState<boolean>(false);
  const menu = useRef();




    return (
      <globalData.Provider value={{ mobMenuEnabled, setMobMenuEnabled }}>
        {
          mobMenuEnabled &&
          <MobMenu myRef={menu} />
        }
        {props.children}
      </globalData.Provider>
    );
}
