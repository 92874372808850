const TwitterSvg = (props:any)=>{
    return(
        <>
        <svg {...props} width="26.288" height="22.532" viewBox="0 0 26.288 22.532">
        <g id="Group_74" data-name="Group 74" transform="translate(0 0)">
            <path id="Path_50" data-name="Path 50" d="M26.289,60.107a10.333,10.333,0,0,1-3.1.9,5.671,5.671,0,0,0,2.37-3.146,10.533,10.533,0,0,1-3.427,1.382,5.251,5.251,0,0,0-3.936-1.8,5.548,5.548,0,0,0-5.393,5.689,6.008,6.008,0,0,0,.14,1.3A15.061,15.061,0,0,1,1.833,58.481a5.888,5.888,0,0,0-.729,2.86,5.776,5.776,0,0,0,2.4,4.736,5.181,5.181,0,0,1-2.443-.714v.071a5.628,5.628,0,0,0,4.325,5.58,5.193,5.193,0,0,1-1.421.2,4.916,4.916,0,0,1-1.015-.106,5.441,5.441,0,0,0,5.037,3.952,10.466,10.466,0,0,1-6.7,2.432A10.867,10.867,0,0,1,0,77.413a14.671,14.671,0,0,0,8.266,2.56c9.92,0,15.343-8.67,15.343-16.19l-.018-.737A11.114,11.114,0,0,0,26.289,60.107Z" transform="translate(-0.001 -57.441)" />
        </g>
        </svg>

        </>
    )
}

export default TwitterSvg;