import { useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../svg/logo_circle.svg";

import { globalData } from "../context/globalContext";
import { MenuType } from "../@types/global";


const Header = ()=>{

    const { mobMenuEnabled, setMobMenuEnabled } = useContext(globalData) as MenuType
    const scroolIntoView = ()=>{
        const element = document.getElementById('contactUs');
        console.log('element: ', element);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }

    return(
        <>
        <div id="header">
            <div className="hUpper">
            <div className="hLeft">

               
                <div className="hLogo">
                    <Link to="/"><img src={Logo} alt="logo" /></Link>
                </div>

                <div className="hlTxtCnt">
                <p className="logoText"><Link to="/">Swarm<sup>TM</sup></Link></p>
                <p className="hAction">&#47;&#47;Tap for a photoshoot</p>
                </div>

                </div>

                <div className="hRight">

                <div className="pcLinks">
                    <p><Link to="/">For customers</Link></p>
                    <p><Link to="/photographer">For photographers</Link></p>
                </div>

                <button className="h-btn" onClick={scroolIntoView}>Contact us</button>

                <div className="menuBtn" onClick={()=> setMobMenuEnabled(!mobMenuEnabled) }>
                    <div>
                        <div className="menuBtnLines"></div>
                        <div className="menuBtnLines"></div>
                        <div className="menuBtnLines"></div>
                    </div>
                </div>

                </div>
            </div>

        </div>
        </>
    );
}

export default Header;