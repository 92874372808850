import { useState } from "react";
import "./App.css";
import Photographer from "./pages/Photographer";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Home from "./pages/home";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import MobMenu from "./components/MobMenu";
import { GlobalContext } from "./context/globalContext";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <GlobalContext>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/photographer" element={<Photographer />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-conditions" element={<Terms />} />
        </Routes>
        <Toaster
          position="top-right" 
        />
      </GlobalContext>
    </>
  );
}

export default App;
