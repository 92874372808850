import Footer from "../components/Footer";
import Header from "../components/Header";

import "../styles/Privacy.css";

import "../App.css";
import ScrollMe from "../components/ScrollMe";
import MobMenu from "../components/MobMenu";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <>
      <Header />

      <div
        id="main"
        className="privacy-policy"
        style={{
          width: "75%",
          margin: "0 auto 0 auto",
          maxWidth: "1000px",
          minHeight: "100vh",
          padding: "200px 0 100px 0",
        }}
      >
        <h1>Privacy Policy</h1>
        <h2>Introduction – Welcome to Swarm! </h2>
        <p>
          This Privacy Policy outlines how we collect, use, disclose, and
          protect your personal information when you use our mobile application
          ("App") and related services ("Services"). By accessing or using the
          App and Services, you agree to the practices described in this Privacy
          Policy.
        </p>
        <ol>
          <li>
            Information We Collect – We may collect various types of information
            from you, including but not limited to:
            {/* Introduction Welcome to SWARM! This Privacy Policy outlines how we
            collect, use, disclose, and protect your personal information when
            you use our mobile application (&quot;App&quot;) and related
            services (&quot;Services&quot;). By accessing or using the App and
            Services, you agree to the practices described in this Privacy
            Policy. */}
            <ul>
              <li>
                Personal Information: Your name, email address, contact
                information, and other identifiable data provided during account
                creation and while using the App.
              </li>
              <li>
                Usage Data: Information related to your interactions with the
                App, such as the pages viewed, features accessed, and actions
                taken.
              </li>
              <li>
                Device Information: Details about your device, including the
                operating system, unique device identifier, and IP address.
              </li>
            </ul>
          </li>

          <li>
            How We Use Your Information – We use the collected information for
            the following purposes:
            <ul>
              <li>To provide and improve our Services.</li>
              <li>
                To communicate with you, respond to inquiries, and send
                important notices.
              </li>
              <li>
                To personalize your experience on the App and tailor content to
                your interests.
              </li>
              <li>
                To monitor and analyze usage patterns to enhance App
                functionality and user experience.
              </li>
              <li>To comply with legal obligations and protect our rights.</li>
            </ul>
          </li>

          <li>
            Data Sharing and Disclosure – We may share your information in the
            following situations:
            <ul>
              <li>
                With Service Providers: We may engage third-party service
                providers to perform functions on our behalf, such as payment
                processing or data analytics.
              </li>
              <li>
                With Other Users: Certain features of the App may allow you to
                share information with other users as per your interactions.
              </li>
              <li>
                For Legal Reasons: We may share information if required by law,
                regulation, legal process, or government request.
              </li>
            </ul>
          </li>
          <li>
            Cookies and Tracking Technologies – Swarm may use cookies and
            similar tracking technologies to enhance user experience and collect
            usage data. By using the App, you consent to the use of cookies as
            outlined in our Cookie Policy.
          </li>
          <li>
            Data Security – We take reasonable measures to protect your
            information from unauthorized access, disclosure, alteration, or
            destruction. However, no method of transmission over the internet or
            electronic storage is entirely secure, and we cannot guarantee
            absolute security.
          </li>
          <li>
            Your Choices and Rights – You have the right to access, update,
            correct, or delete your personal information. You can exercise these
            rights through your App settings or by contacting us at{" "}
            <Link to="mailto:support@get-swarm.com">support@get-swarm.com</Link>
            . You can also opt-out of marketing communications by following the
            unsubscribe instructions provided.
          </li>
          <li>
            Children's Privacy – Swarm does not knowingly collect personal
            information from children under the age of 13. If you believe a
            child has provided personal information, please contact us, and we
            will promptly delete it.
          </li>
          <li>
            Changes to this Privacy Policy – We may update this Privacy Policy
            from time to time to reflect changes in our practices or for other
            operational, legal, or regulatory reasons. Any such changes will be
            posted on the App, and the revised version will become effective on
            the date indicated.
          </li>
          <li>
            Contact Us If you have any questions, concerns, or requests related
            to this Privacy Policy or our privacy practices, please contact us
            at{" "}
            <Link to="mailto:support@get-swarm.com">support@get-swarm.com</Link>
            .
          </li>
        </ol>

        <h2>Thank you for using Swarm!</h2>
      </div>

      <Footer />

      <ScrollMe />
    </>
  );
};

export default Privacy;
