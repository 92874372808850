import { useEffect, useRef, useState } from "react";
import Style from "../../styles/Switch.module.css";
type SwitchProps = {
  theme: string;
  text: string[];
  preventScale?: boolean;
  setType: any;
};
const Switch = ({
  theme,
  text,
  preventScale,
  setType,
}: SwitchProps) => {
  const [checked, setChecked] = useState(false);
  const [pcView, setPcView] = useState(false);
  const circle = useRef<any>();

  const activate = (e: any) => {
    setChecked(!checked);
  };

  useEffect(() => {
    let parent = circle.current.parentNode;
    if (checked) {
      setType(text[1]);
    } else {
      setType(text[0]);
    }
    console.log("parent: ", parent);

    console.log("checked: ", checked);
    if (checked) {
      let calc = parent.clientWidth - circle.current.clientWidth;
      circle.current.style.transform = `translateX(${calc}px)rotate(-90deg)`;
    } else {
      circle.current.style.transform = `translateX(0px)rotate(0deg)`;
    }
  }, [checked]);

  useEffect(() => {
    window.addEventListener("resize", resizeFunc);
    resizeFunc();

    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, []);

  const resizeFunc = () => {
    if (window.innerWidth >= 1000) {
      setPcView(true);
    } else {
      setPcView(false);
    }
  };

  return (
    <>
      <div className={Style.switchCnt}>
        <p
          className={`${!preventScale ? Style.txt : Style.txt2} ${
            theme === "dark"
              ? !checked
                ? Style.txtDarkActive
                : Style.txtDark
              : !checked
              ? Style.txtLightActive
              : Style.txtLight
          }`}
        >
          {pcView && text ? text[0] : text[0]}
        </p>
        <div
          className={`${Style.barCnt} ${
            theme === "dark" ? Style.darkBarCnt : Style.lightBarCnt
          }`}
        >
          <div className={Style.switchBox} onClick={activate} ref={circle}>
            <div className={Style.btn}></div>
            <div className={Style.btnBk}></div>
          </div>
        </div>
        <p
          className={`${!preventScale ? Style.txt : Style.txt2} ${
            theme === "dark"
              ? checked
                ? Style.txtDarkActive
                : Style.txtDark
              : checked
              ? Style.txtLightActive
              : Style.txtLight
          }`}
        >
          {pcView && text ? text[1] : text[1]}
        </p>
      </div>
    </>
  );
};

export default Switch;
