import { Input, Modal } from "antd";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/Photographer.css";
import { useEffect, useState } from "react";
import ScrollMe from "../components/ScrollMe";
import { Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import MobileSvg from "../svg/mobile.svg";
import TwitterSvg from "../svg/Twitter";
import LinkedinSvg from "../svg/LinkedIn";
import InstaSvg from "../svg/Insta";
import TiktokSvg from "../svg/Tiktok";
import MobMenu from "../components/MobMenu";
import { toast } from "react-hot-toast";
import ContactForm from "../components/ContactForm";
import axios from "axios";
const Fade = require("react-reveal/Fade");

const Terms = require("../files/Terms.pdf");
const Privacy = require("../files/Privacy.pdf");
const SwarmCommunityGuidelines = require("../files/SwarmCommunityGuidelines.pdf");

const Photographer = () => {
  const [mobScreen, setMobScreen] = useState(true);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("Photographer");

  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    // setTimeout(() => {
    //   setIsModalOpen(false);
    // }, 3000);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!email || !name) {
      toast.error("Please fill in all fields.");
      return;
    }

    // const formData = new FormData();
    // formData.append("EMAIL", email);
    // formData.append("FNAME", name);
    // formData.append("RADIO", type);

    // try {
    //   const response = await fetch(
    //     "https://get-swarm.us21.list-manage.com/subscribe/post?u=63fe510beff280c6fe14e3995&amp;id=da9a86e3d7&amp;f_id=0072e2e6f0",
    //     {
    //       method: "POST",
    //       body: formData,
    //       mode: "no-cors",
    //     }
    //   );

    //   console.log("response: ", response);
    //   if (response.status === 200) {
    //     console.log("Subscription successful");
    //     toast.success("Success");
    //   }
    // } catch (error) {
    //   toast.error("Error");
    //   console.error("Error:", error);
    // }
    const formData = {
      type,
      email,
      FNAME: name,
      LNAME: "",
    };

    console.log("Form Data with type:", formData);

    await axios
      .post(
        "https://dev-azproduction-api.flynautstaging.com/auth/subscribe-mailchimp",
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Form submitted successfully");
          toast.success("Success");
          setEmail("");
          setName("");

          setModalHeading("Thank you for signing up!");
          setModalText("A member of our team will be in touch.");
          setIsModalOpen(true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          // console.error("Form submission error:", response.statusText);
          // toast.error("Something went wrong");
          toast.error(response?.data?.error);

          setModalHeading("There was an error signing up.");
          setModalText("Please check your info and try again.");
          setIsModalOpen(true);
          setTimeout(() => {
            setIsModalOpen(false);
            setModalHeading("");
            setModalText("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Form submission error:", error);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    window.addEventListener("resize", resizeFunc);
    resizeFunc();

    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, []);

  const resizeFunc = () => {
    if (window.innerWidth >= 1000) {
      setMobScreen(false);
    } else {
      setMobScreen(true);
    }
  };

  const scroolIntoView = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header />

      <section className="pTopSection">
        <div className="ptBelow">
          <Fade bottom>
            <div className="hBot">
              <Link to="https://twitter.com/get_swarm">
                <TwitterSvg className="w-logo" />
              </Link>

              <Link to="https://www.linkedin.com/company/swarm-app/">
                <LinkedinSvg className="w-logo" />
              </Link>

              <Link to="https://www.instagram.com/get.swarm/">
                <InstaSvg className="w-logo" />
              </Link>

              {/* <img src={fb} alt="icon" /> */}
              <Link to="https://www.tiktok.com/@get.swarm">
                <TiktokSvg className="w-logo" />
              </Link>
            </div>
          </Fade>

          <div className="ptbContent">
            <Fade bottom>
              <p className="ptbHeading">FOR PHOTOGRAPHERS</p>
            </Fade>
            <Fade bottom>
              <p className="ptbSubHeading">
                Seeking top talent
                <br /> across NYC
              </p>
            </Fade>
          </div>
        </div>

        <div className="ptAbove">
          <div className="ptaContent">
            <Fade bottom>
              <img
                className="ptacImage"
                src="/images/photographer.png"
                alt="photographer"
              />
            </Fade>
            <Fade bottom>
              <button
                className="btn htBtn"
                style={{ margin: "0" }}
                onClick={() => scroolIntoView("join")}
              >
                Join the community
              </button>
            </Fade>
            <Fade bottom>
              <div className="aside app-buttons">
                <Link to="#">
                  <img src="./images/app-store.png" alt="App Store" />
                </Link>
                <Link to="#">
                  <img src="./images/google-play.png" alt="Google Play" />
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section className="btfCnt btfFirst photographer" id="join">
        <div>
          <Fade bottom>
            <p className="btnMobMsg" onClick={handleSubmit}>
              SIGN UP
            </p>
          </Fade>

          <Fade bottom>
            <p className="b-heading">Join Swarm as a photographer</p>
          </Fade>

          <Fade bottom>
            <p className="g-subHeading">
              The Swarm app will go live in March 2024. Sign up now to become a
              photographer with us.
            </p>
          </Fade>

          <Fade bottom>
            <div className="btfBtnCnt">
              <div>
                <div className="superInpCnt">
                  <label>Your name</label>
                  <div className="inpCnt">
                    <div className="inpCntInner">
                      <Input
                        bordered={false}
                        placeholder="Your name"
                        className="myselect"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="superInpCnt">
                  <label>Email address</label>
                  <div className="inpCnt">
                    <div className="inpCntInner">
                      <Input
                        bordered={false}
                        placeholder="Email address"
                        className="myselect"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <button
                      className="btn pcBtn"
                      style={{}}
                      onClick={handleSubmit}
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              </div>

              <button className="btn htBtn mobBtn" onClick={handleSubmit}>
                Sign up
              </button>
            </div>
          </Fade>
        </div>
      </section>

      <section className="makeAmove">
        <div className="mavTile">
          <Fade right>
            <div className="mavtHeading">
              <div>
                <p className="y-heading leftAlign">
                  CALLING ALL PASSIONATE PHOTOGRAPHERS
                </p>
                <p className="b-heading leftAlign">
                  Make great money doing what you love most in life.
                </p>
              </div>
            </div>
          </Fade>

          <Fade left>
            <div className="mavtImage">
              <img src="/images/mav1.jpeg" alt="photographer" />
            </div>
          </Fade>

          <Fade right>
            <div className="mavtContent">
              <div>
                <p className="g-subHeading leftAlign">
                  You’re a pro photographer with 20 years of experience—maybe
                  you’ve had your own studio, and an expansive portfolio of
                  work, and now you’re looking for a new outlet to continue
                  growing your brand. Or, maybe you’re a hobbyist with a fancy
                  camera, who loves shooting, but has never had a way to earn
                  money doing it. Maybe you’re more of an amateur, with an
                  iPhone, who has grown a huge following on Instagram, and would
                  like to turn that talent and influence into bank.
                  <br />
                  <br />
                  Swarm photographers come from all different backgrounds, ages,
                  and levels of experience. But, all have one thing in common: A
                  love for photography. Come join our community of talent—and
                  all of the excitement, influence, success, and growth that
                  will come with it.
                  <br />
                  <br />
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: `${mobScreen ? "center" : "flex-start"}`,
                  }}
                >
                  <button
                    className={mobScreen ? "btn htBtn" : "btn htBtn"}
                    style={{ width: `${!mobScreen && "400px"}`, margin: "0" }}
                    onClick={() => scroolIntoView("tileContent")}
                  >
                    Learn what you could earn
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <div className="mavTile">
          <Fade left>
            <div className="mavtHeading">
              <div>
                <p className="y-heading leftAlign">DESIGN YOUR OWN SCHEDULE</p>
                <p className="b-heading leftAlign">
                  Make great work, and make bank, at the same time.
                </p>
              </div>
            </div>
          </Fade>

          <Fade right>
            <div className="mavtImage">
              <img src={MobileSvg} alt="mobile screen" />
            </div>
          </Fade>

          <Fade left>
            <div className="mavtContent">
              <div>
                <p className="g-subHeading leftAlign">
                  Swarm aims to replenish and re-launch a once-thriving
                  photography industry that has taken a huge hit since the dawn
                  of the iPhone. To all of the pro photographers out there whose
                  once thriving careers have been impacted by modern
                  technology—we want you to join our movement and help us
                  reclaim a beautiful craft that no “device” could ever replace.
                  <br />
                  <br />
                  There’s a place for every photographer in our
                  community—amateur, hobbyist, and pro—all you have to do is
                  submit your profile and leave the rest to us. Below, you’ll
                  learn more about the three levels of Swarm talent, including
                  how much Swarm photographers can earn. Where do you fit in?
                  <br />
                  <br />
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: `${mobScreen ? "center" : "flex-start"}`,
                  }}
                >
                  <button
                    className="btn htBtn"
                    style={{ margin: "0" }}
                    onClick={() => scroolIntoView("tileContent")}
                  >
                    See where you fit in
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>

      <section className="bookShot">
        <div>
          <Fade bottom>
            <p className="y-heading">JOIN OUR AWESOME COMMUNITY</p>
          </Fade>
          <Fade bottom>
            <p className="b-heading">
              Submit your profile in five mins or less.
            </p>
          </Fade>
          <Fade bottom>
            <p className="g-subHeading">
              It only takes a few minutes to sign up and submit your profile.
              Once submitted, we’ll reach out to arrange a quick meeting with
              you. We meet with, and vet, all of our talent to ensure the best
              possible experience for our customers.
            </p>
          </Fade>

          <div className="stepsCnt joinUs">
            <Fade left>
              <div className="stepBox">
                <img src="./images/pstep1.png" alt="step" />
                <div className="stepTxtCnt">
                  <div className="yellowCircle">1</div>
                  <p className="g-subHeading">
                    Sign up for Swarm, create your new account as a Photographer
                    and fill out your profile.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div className="stepBox">
                <img src="./images/pstep2.png" alt="step" />
                <div className="stepTxtCnt">
                  <div className="yellowCircle">2</div>
                  <p className="g-subHeading">
                    Pick the city you live and work in. Then, pick the hoods you
                    frequent.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade right>
              <div className="stepBox">
                <img src="./images/pstep3.png" alt="step" />
                <div className="stepTxtCnt">
                  <div className="yellowCircle">3</div>
                  <p className="g-subHeading">
                    Let us know what your level of experience is—amateur,
                    mid-level or pro.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade left>
              <div className="stepBox">
                <img src="./images/pstep4.png" alt="step" />
                <div className="stepTxtCnt">
                  <div className="yellowCircle">4</div>
                  <p className="g-subHeading">
                    Pick the days and times you’d like to work. You’ll be able
                    to edit your schedule in the future.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div className="stepBox">
                <img src="./images/pstep5.png" alt="step" />
                <div className="stepTxtCnt">
                  <div className="yellowCircle">5</div>
                  <p className="g-subHeading">
                    Upload a portfolio of shots that best represents your brand.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade right>
              <div className="stepBox">
                <img src="./images/pstep6.png" alt="step" />
                <div className="stepTxtCnt">
                  <div className="yellowCircle">6</div>
                  <p className="g-subHeading">
                    Finally, submit your profile. Once we receive it, we’ll
                    review it and reach out to arrange a time to chat with you.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section className="gridCnt pGrid">
        <div className="tile">
          <Fade left>
            <div className="tileImgCnt"></div>
          </Fade>

          <Fade right>
            <div className="tileContent">
              <div>
                <p className="b-heading leftAlign" id="tileContent">
                  Amateur talent
                </p>
                <p className="g-subHeading leftAlign">
                  You’re in school, or you’re an influencer, taking incredible
                  pics on your phone, and looking for a fun job where you can
                  make your own schedule, work with interesting people all
                  across the city, and turn your influence into reliable work
                  and steady income. Join our community of talent as an amateur
                  photographer and earn between $25-50/hr + tips for each shoot.
                </p>
              </div>
            </div>
          </Fade>
        </div>

        <div className="tile">
          <Fade right>
            <div className="tileImgCnt"></div>
          </Fade>

          <Fade left>
            <div className="tileContent">
              <div>
                <p className="b-heading leftAlign">Hobbyists</p>
                <p className="g-subHeading leftAlign">
                  You’ve always been passionate about photography and probably
                  even own a camera or two. You’ve never really had a job as a
                  photographer, and don’t consider yourself to be a pro (yet),
                  but would love an opportunity to grow your passion, and get
                  paid for it. And, you may even have a day job that you would
                  love to be able to trade in for a life as a photographer. Join
                  our community of talent as a mid-level photographer, and earn
                  between $50-100/hr + tips for each shoot.
                </p>
              </div>
            </div>
          </Fade>
        </div>

        <div className="tile">
          <Fade left>
            <div className="tileImgCnt"></div>
          </Fade>

          <Fade right>
            <div className="tileContent">
              <div>
                <p className="b-heading leftAlign">Pro photographers</p>
                <p className="g-subHeading leftAlign">
                  Photography is your superpower, profession, and greatest
                  passion in life. You’ve likely had your own studio, roster of
                  fancy clients, and know the best spots to buy or rent
                  equipment. But, the era of smartphones dawned, you’ve taken a
                  hit, and would welcome more work that’s reliable, work you
                  don’t need to manage, and the steady income that comes with
                  it. Join our community of talent as a pro photographer and
                  earn between $100-300/hr + tips for each shoot.
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </section>

      <section className="makeAmove guidelines">
        <div className="mavTile">
          <Fade left>
            <div className="mavtHeading">
              <div>
                <p className="y-heading leftAlign">PLEASE TAKE A MINUTE TO</p>
                <p className="b-heading leftAlign">
                  Check out our community guidelines.
                </p>
              </div>
            </div>
          </Fade>

          <Fade right>
            <div className="mavtImage">
              <div className="mavtImageHolder">
                <img src="/images/photo_guide.jpg" alt="guide" />
                <div className="shadowCircle"></div>
              </div>
            </div>
          </Fade>
          <Fade left>
            <div className="mavtContent">
              <div>
                <p className="g-subHeading leftAlign">
                  From “welcome to Swarm” and basic community guidelines to
                  onboarding and payment structure—our Community Guidelines have
                  all of the information you need to understand the ins and outs
                  of joining us, and working as a Swarm photographer.
                  <br />
                  <br />
                  All Swarm photographers will be required to review, sign, and
                  adhere to these guidelines prior to working with us.
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: `${mobScreen ? "center" : "flex-start"}`,
                    margin: `${mobScreen ? "0" : "0 0 0 0"}`,
                  }}
                >
                  <a href={SwarmCommunityGuidelines} target="_blank">
                    <button
                      className="btn htBtn"
                      style={{
                        width: `${mobScreen ? "100%" : "auto"}`,
                        maxWidth: "500px",
                      }}
                    >
                      Download it now
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>

      <section className="form" id="contactUs">
        <Fade left>
          <div className="formLeft">
            <div className="flContent">
              <p className="y-heading leftAlign">CONTACT US</p>
              <p className="b-heading leftAlign">We’d love to hear from you</p>
              <p className="g-subHeading leftAlign">
                We’re excited to launch the Swarm app in New York City this
                September. Whether you’re a customer wanting to download the
                app, a photographer wanting to sign up to join our community of
                talent, or an influencer wanting to collaborate with us, please
                fill out the form and, we’ll get back to you as soon as
                possible!
              </p>
            </div>
          </div>
        </Fade>

        <Fade right>
          <ContactForm />
          {/* <div className="formRight">
            <div>
              <div className="mainFormInpCnt mobInput">
                <label>First and Last Name</label>
                <div className="formInpCnt">
                  <Input
                    type="text"
                    placeholder="First and Last Name"
                    bordered={false}
                    className="formInp"
                  />
                </div>
              </div>

              <div className="twoRow pcInput">
                <div className="mainFormInpCnt">
                  <label>First Name</label>
                  <div className="formInpCnt">
                    <Input
                      type="text"
                      placeholder="First Name"
                      bordered={false}
                      className="formInp"
                    />
                  </div>
                </div>

                <div className="mainFormInpCnt">
                  <label>Last Name</label>
                  <div className="formInpCnt">
                    <Input
                      type="text"
                      placeholder="Last Name"
                      bordered={false}
                      className="formInp"
                    />
                  </div>
                </div>
              </div>

              <div className="mainFormInpCnt">
                <label>Email</label>
                <div className="formInpCnt">
                  <Input
                    type="email"
                    placeholder="Your email"
                    bordered={false}
                    className="formInp"
                  />
                </div>
              </div>

              <div className="mainFormInpCnt txtarea">
                <label>Send us a message</label>
                <div className="formInpCnt">
                  <TextArea
                    className="formInp"
                    bordered={false}
                    rows={!mobScreen ? 3 : 4}
                  />
                </div>
              </div>

              <div className="checkboxCnt">
                <input type="checkbox" id="checkbox" />
                <label htmlFor="checkbox">
                  I agree to all the{" "}
                  <a href={Terms} target="_blank">
                    Terms
                  </a>{" "}
                  and{" "}
                  <a href={Privacy} target="_blank">
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>

              <button className={"btn htBtn"}>Connect with us</button>
            </div>
          </div> */}
        </Fade>
      </section>

      <section className="social">
        <div>
          <Fade bottom>
            <p className="y-heading">WE'RE QUITE SOCIAL</p>
          </Fade>

          <Fade bottom>
            <p className="b-heading">
              And, we’d be honored to have you follow us
            </p>
          </Fade>

          <Fade bottom>
            <div className="iconbox">
              <Link to="https://twitter.com/get_swarm">
                <TwitterSvg className="w-logo" />
              </Link>

              <Link to="https://www.linkedin.com/company/swarm-app/">
                <LinkedinSvg className="w-logo" />
              </Link>

              <Link to="https://www.instagram.com/get.swarm/">
                <InstaSvg className="w-logo" />
              </Link>

              {/* <img src={fb} alt="icon" /> */}
              <Link to="https://www.tiktok.com/@get.swarm">
                <TiktokSvg className="w-logo" />
              </Link>
            </div>
          </Fade>
        </div>
      </section>

      <Footer />
      <ScrollMe />
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={null}
        centered
        wrapClassName="message-modal"
        width={"650px"}
      >
        {modalHeading && <h2>{modalHeading}</h2>}
        {modalText && <p>{modalText}</p>}
      </Modal>
    </>
  );
};

export default Photographer;
