import React, { Component } from "react";
import { Link } from "react-router-dom";
import insta from "../svg/insta.svg";
import linkedin from "../svg/linkedin.svg";
import tiktok from "../svg/tiktok.svg";
import twitter from "../svg/twitter.svg";
import Logo from "../svg/logo_circle.svg";
const Terms = require("../files/Terms.pdf");
const Privacy = require("../files/Privacy.pdf");

class Download extends Component {
  render() {
    return (
      <>
        <p>
          {/* <a href={Terms} target="_blank">
            Privacy Policies
          </a> */}
          <p>
            <Link to={"/privacy-policy"}>Privacy Policies</Link>
          </p>
        </p>
        <p>
          {/* <a href={Privacy} target="_blank">
            Terms & Conditions
          </a> */}
          <p>
            <Link to={"/terms-conditions"}>Terms & Conditions</Link>
          </p>
        </p>
      </>
    );
  }
}

const Footer = () => {
  const scroolIntoView = () => {
    const element = document.getElementById("bottomSignup");
    console.log("element: ", element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scroolIntoViewContactUs = () => {
    const element = document.getElementById("contactUs");
    console.log("element: ", element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scroolIntoViewAboutUs = () => {
    const element = document.getElementById("aboutUs");
    console.log("element: ", element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const year = new Date().getFullYear();

  return (
    <>
      <footer id="mobFooter">
        <div>
          <div className="mfTop">
            <p>
              <Link to="/">For customers</Link>
            </p>
            <p>
              <Link to="/photographer">For photographers</Link>
            </p>
          </div>

          <div className="mfContact">
            <p className="mfcHeading" onClick={scroolIntoView}>
              CONTACT US
            </p>
            {/* <p className="mfcAddress">
              666 Greenwich st
              <br /> New York, 10014
            </p>
            <p className="mfcPhone">
              <a href="tel: +17372921248 ">+1 646-493-7111</a>
            </p> */}
            <p className="mfcEmail">
              <a href="mailto: info@get-swarm.com">info@get-swarm.com</a>
            </p>
          </div>

          <div className="mfLogoHolder">
            <img className="mfLogo" src={Logo} alt="logo" />
            <p className="mfLogoName">
              Swarm<sup>TM</sup>
            </p>
            <p className="mfLogoSubName">Tap for a photoshoot</p>
          </div>

          <div className="mfCopyRight">
            &#169; {year} Get-swarm.com
            <br />
            All rights reserved.
          </div>

          <div className="mfLogoCnt">
            <Link to="https://twitter.com/get_swarm">
              <img src={twitter} alt="icon" />
            </Link>

            <Link to="https://www.linkedin.com/company/swarm-app/">
              <img src={linkedin} alt="icon" />
            </Link>

            <Link to="https://www.instagram.com/get.swarm/">
              <img src={insta} alt="icon" />
            </Link>

            {/* <img src={fb} alt="icon" /> */}
            <Link to="https://www.tiktok.com/@get.swarm">
              <img src={tiktok} alt="icon" />
            </Link>
          </div>
        </div>
        <div className="mbfExtra">
          <Download />
        </div>
      </footer>

      <footer id="footer">
        <div>
          <div className="fbox pcFlogoHolder">
            <div className="pcftop">
              <div className="pcftLeft">
                <img src={Logo} alt="logo" />
              </div>
              <div className="pcftRight">
                <p className="mfLogoName">
                  Swarm<sup>TM</sup>
                </p>
                {/* <p className="mfLogoSubName">Tap for a photoshoot</p> */}
              </div>
            </div>

            <div className="pcfbot">
              <p>
                © {year} Get-swarm.com
                <br />
                All rights reserved.
              </p>
            </div>
          </div>

          <div className="fbox fboxmenu">
            <h3>MENU</h3>
            <p onClick={scroolIntoViewAboutUs}>About</p>
            <p>
              <Link to="/">Customers</Link>
            </p>
            <p>
              <Link to="/photographer">Photographers</Link>
            </p>
            {/* <p>Brands</p> */}
            <p onClick={scroolIntoViewContactUs}>Contact us</p>
          </div>

          <div className="fbox fboxlegal">
            <h3>LEGAL</h3>
            <p>
              <Link to={"/privacy-policy"}>Privacy Policies</Link>
            </p>
            <p>
              <Link to={"/terms-conditions"}>Terms & Conditions</Link>
            </p>
          </div>
          <div className="fbox fboxconnect">
            <h3>CONNECT</h3>

            <div className="footerIbox">
              <Link to="https://twitter.com/get_swarm">
                <img src={twitter} alt="icon" />
              </Link>

              <Link to="https://www.linkedin.com/company/swarm-app/">
                <img src={linkedin} alt="icon" />
              </Link>

              <Link to="https://www.instagram.com/get.swarm/">
                <img src={insta} alt="icon" />
              </Link>

              <Link to="https://www.tiktok.com/@get.swarm">
                <img src={tiktok} alt="icon" />
              </Link>
            </div>
            <a href="mailto: info@get-swarm.com">info@get-swarm.com</a>
          </div>
          <div className="fbox fboxcontact">
            <h3>DOWNLOAD OUR APP</h3>
            <p>On-demand photoshoots</p>

            <div className="aside app-buttons">
              <Link to="#">
                <img src="./images/app-store.png" alt="App Store" />
              </Link>
              <Link to="#">
                <img src="./images/google-play.png" alt="Google Play" />
              </Link>
            </div>
            {/* <p style={{ marginTop: "40px" }}>
              <a href="tel: +1646-493-7111">+1 646-493-7111</a>
            </p> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
