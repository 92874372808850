import React, { useEffect } from "react";

const ScrollMe = ()=>{


    useEffect(()=>{

        window.scrollTo(0,0);

    },[])


    return(
        <>
        </>
    )


}

export default ScrollMe;