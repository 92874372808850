const LinkedinSvg = (props:any)=>{

    return(
        <>
        <svg {...props} height="22.532" viewBox="0 0 22.533 22.532">
        <path id="Path_62774" data-name="Path 62774" d="M19.2,19.2H15.861V13.971c0-1.247-.022-2.852-1.736-2.852-1.739,0-2,1.358-2,2.761V19.2H8.78V8.447h3.205V9.916h.045a3.513,3.513,0,0,1,3.162-1.737c3.384,0,4.008,2.226,4.008,5.121ZM5.013,6.977A1.937,1.937,0,1,1,6.95,5.039,1.938,1.938,0,0,1,5.013,6.977M6.683,19.2H3.34V8.447H6.683ZM20.864,0H1.663A1.644,1.644,0,0,0,0,1.626V20.906a1.645,1.645,0,0,0,1.663,1.626h19.2a1.649,1.649,0,0,0,1.669-1.626V1.624A1.648,1.648,0,0,0,20.864,0" transform="translate(0 0)" />
        </svg>

        </>
    )
}

export default LinkedinSvg;