const TiktokSvg = (props:any)=>{

    return(
        <>
        <svg {...props} width="21.446" height="24.41" viewBox="0 0 21.446 24.41">
        <path id="Path_21067" data-name="Path 21067" d="M52.857,6.5A6.15,6.15,0,0,1,49.141,5.26,6.152,6.152,0,0,1,46.684.39H42.7V11.281l0,5.965a3.613,3.613,0,1,1-2.478-3.423V9.776a7.843,7.843,0,0,0-1.149-.085,7.643,7.643,0,0,0-5.743,2.568,7.49,7.49,0,0,0,.338,10.328,7.776,7.776,0,0,0,.72.629A7.643,7.643,0,0,0,39.066,24.8a7.843,7.843,0,0,0,1.149-.085,7.617,7.617,0,0,0,4.255-2.128,7.462,7.462,0,0,0,2.238-5.31l-.021-8.908a10.1,10.1,0,0,0,6.18,2.1V6.5s-.011,0-.011,0Z" transform="translate(-31.423 -0.39)" />
        </svg>
        </>
    )
}

export default TiktokSvg;