import { useContext } from "react";
import { Link,Navigate,redirect, useNavigate } from "react-router-dom";

import { globalData } from "../context/globalContext";
import { MenuType } from "../@types/global";


type propsType={
    myRef:any
}
const MobMenu = ({myRef}:propsType)=>{

    const navi = useNavigate();
    const { mobMenuEnabled, setMobMenuEnabled } = useContext(globalData) as MenuType

    const rdr = (val:string)=>{

        setMobMenuEnabled(false);
        navi(val);


    }

    return(
        <>

        <div className="mobMenu" ref={myRef}>
            <div className="mmLeft">
                <ul>
                    <li onClick={()=> rdr("/")}>For customers</li>
                    <li onClick={()=> rdr("/photographer")}>For photographers</li>
                </ul>

            </div>
            <div className="mmRight" onClick={()=> setMobMenuEnabled(!mobMenuEnabled) } >

            </div>

        </div>
        
        </>
    )

}

export default MobMenu;