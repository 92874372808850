import { Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const ContactForm = () => {
  const [showPlaceHolder, setShowPlaceHolder] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    // setTimeout(() => {
    //   setIsModalOpen(false);
    // }, 3000);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeFunc);
    resizeFunc();
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, []);

  const resizeFunc = () => {
    if (window.innerWidth >= 1000) {
      setShowPlaceHolder(true);
    } else {
      setShowPlaceHolder(false);
    }
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    agreeToTerms: false,
  });

  // Function to handle form field changes
  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    // If the input is a checkbox, update its value differently
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Function to handle form submission

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Function to check if the form is valid
    const isFormValid = () => {
      const { firstName, lastName, email, message, agreeToTerms } = formData;
      // Check if all required fields are non-empty and agreeToTerms is true
      return firstName && lastName && email && message && agreeToTerms;
    };

    if (!isFormValid()) {
      toast.error("Form is invalid, please fill out all required fields.");
      setModalHeading("There was an error.");
      setModalText("Please check your info and try again.");
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        setModalHeading("");
        setModalText("");
      }, 3000);
      return;
    }

    try {
      const response = await axios.post(
        "https://get-swarm.com/api/send-email",
        formData
      );

      console.log("Response data:", response.data.message);

      toast.success(response?.data?.message);

      setModalHeading("Thank you for contacting us!");
      setModalText("A member of our team will be in touch.");
      setIsModalOpen(true);

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast.error("Error");
      console.log("error: ", error);

      setModalHeading("There was an error.");
      setModalText("Please check your info and try again.");
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        setModalHeading("");
        setModalText("");
      }, 3000);
    }
    console.log(formData);
    console.log("Form data:", formData);
  };

  // const handleSubmit = async (e: any) => {
  //   e.preventDefault();
  //   try {
  //     await axios.post("https://get-swarm.com/api/send-email", formData);
  //     toast.success("Done");
  //     window.location.reload();
  //   } catch (error) {
  //     toast.error("Error");
  //     console.log("error: ", error);
  //   }
  //   console.log(formData);
  //   // Use formData to make API calls or perform other actions
  //   console.log("Form data:", formData);
  // };

  return (
    <>
      <div className="formRight">
        <div>
          {/* <div className="mainFormInpCnt mobInput">
        <label>First and Last Name</label>
        <div className="formInpCnt">
          <Input
            type="text"
            placeholder="First and Last Name"
            bordered={false}
            className="formInp"
            value={formData.firstName}
            onChange={handleInputChange}
          />
        </div>
      </div> */}
          <div className="mainFormInpCnt mobInput">
            <label>First and Last Name</label>
            <div className="formInpCnt">
              <Input
                type="text"
                placeholder="First Name"
                bordered={false}
                className="formInp"
                value={formData.firstName}
                name="firstName"
                onChange={handleInputChange}
              />
              <Input
                type="text"
                placeholder="Last Name"
                bordered={false}
                className="formInp"
                value={formData.lastName}
                name="lastName"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="twoRow pcInput">
            <div className="mainFormInpCnt">
              <label>First Name</label>
              <div className="formInpCnt">
                <Input
                  type="text"
                  placeholder="First Name"
                  bordered={false}
                  className="formInp"
                  value={formData.firstName}
                  name="firstName"
                  onChange={(e) => {
                    console.log("a;isan", e.target.value);
                    handleInputChange(e);
                  }}
                />
              </div>
            </div>

            <div className="mainFormInpCnt">
              <label>Last Name</label>
              <div className="formInpCnt">
                <Input
                  type="text"
                  placeholder="Last Name"
                  bordered={false}
                  className="formInp"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="mainFormInpCnt">
            <label>Email</label>
            <div className="formInpCnt">
              <Input
                type="email"
                placeholder="Your email"
                bordered={false}
                className="formInp"
                value={formData.email}
                name="email"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="mainFormInpCnt txtarea">
            <label>Send us a message</label>
            <div className="formInpCnt">
              <TextArea
                className="formInp"
                bordered={false}
                rows={showPlaceHolder ? 3 : 4}
                value={formData.message}
                name="message"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="checkboxCnt">
            <input
              type="checkbox"
              id="checkbox"
              checked={formData.agreeToTerms}
              name="agreeToTerms"
              onChange={handleInputChange}
            />
            <label htmlFor="checkbox">
              I agree to all the <Link to={"/terms-conditions"}>Terms</Link> and{" "}
              <Link to={"/privacy-policy"}>Privacy Policy</Link>.
            </label>
          </div>

          <button className={"btn htBtn"} onClick={handleSubmit}>
            Connect with us
          </button>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={null}
        centered
        wrapClassName="message-modal"
        width={"650px"}
      >
        {modalHeading && <h2>{modalHeading}</h2>}
        {modalText && <p>{modalText}</p>}
      </Modal>
    </>
  );
};

export default ContactForm;
